import { cards } from "../../data/cards";
import "./index.css";

const Deals = () => {
  return (
    <div className="deals_wrapper">
      <p className="deals_heading">
        Find your next adventure with these <span>flight deals</span>
      </p>
      <div className="deals_grid">
        {cards?.map((item) => <Card item={item} />).slice(0, 3)}
      </div>
      <Card item={cards[cards?.length - 1]} />
    </div>
  );
};

const Card = ({ item }) => {
  return (
    <div
      className="deals_card"
      key={item?.id}
    >
      <img
        src={item?.image}
        alt={item?.description}
      />
      <div>
        <p className="deals_card_location">
          {item?.location}, <span>{item?.city}</span>
        </p>
        <p className="deals_card_description">{item?.description}</p>
      </div>
    </div>
  );
};

export default Deals;
