import "./index.css";
import american from "../../assets/flights/american.svg";
import { useState } from "react";
import CustomModal from "../Modal/modal";

const FlightList = ({ data, setData, isClickable, isActive }) => {
  const [open, setOpen] = useState(false);
  const [itemData, setItemData] = useState();

  const selectPriceHandle = (item, type) => {
    if (!isClickable) return;
    if (type === "economy") {
      setData({
        ...item,
        price: item?.price_economy,
        discounted: item?.economy_discount,
        fee: item?.economy_fee,
        type,
      });
    } else if (type === "premium") {
      setData({
        ...item,
        price: item?.price_premium,
        discounted: item?.premium_discount,
        fee: item?.premium_fee,
        type,
      });
    }
  };

  return (
    <>
      <div className={`flight_list ${isActive && "active"}`}>
        {data?.map((item, idx) => (
          <div
            className="flight_item"
            key={item?.id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (!isClickable) return;
              if (item?.price_economy > 0) {
                setItemData(item);
                setOpen(true);
              } else if (item.price_premium > 0) {
                selectPriceHandle(item, "premium");
              }
            }}
          >
            <div className="flight_airline">
              <img
                src={american}
                alt="American Airlines"
              />
              <div>
                <p className="black">{item?.flight_duration}</p>
                <p>American Airlines</p>
                {item?.flight_number?.split(";")?.map((fn) => (
                  <p style={{ fontSize: 14 }}>{fn}</p>
                ))}
              </div>
            </div>
            <div className="flex-col-center">
              <p
                className="black"
                style={{ textTransform: "uppercase" }}
              >
                {item?.departure_time} - {item?.arrival_time}
              </p>
            </div>
            <div className="start end">
              <p className="black">{item?.flight_stop}</p>
              {item?.layover_city && <p>{item?.layover_city}</p>}
            </div>
            {isClickable && (
              <div className="end">
                {item.price_economy > 0 ? (
                  <div className="flight_list_price">
                    <label className="black">
                      {item?.economy_discount ? (
                        <>
                          <span style={{ textDecoration: "line-through" }}>
                            ${item?.price_economy}
                          </span>{" "}
                          <span style={{ color: "green" }}>
                            ${item?.economy_discount}
                          </span>
                        </>
                      ) : (
                        <span>${item?.price_economy}</span>
                      )}
                    </label>
                    {/* <input type="radio" name="price" id={`price_economy_${idx}`}  /> */}
                    {/* <span className="price_type">(Economy)</span> */}
                  </div>
                ) : item.price_premium > 0 ? (
                  <div className="flight_list_price">
                    <label className="black">
                      {item?.premium_discount ? (
                        <>
                          <span style={{ textDecoration: "line-through" }}>
                            ${item?.price_premium}
                          </span>{" "}
                          <span style={{ color: "green" }}>
                            ${item?.premium_discount}
                          </span>
                        </>
                      ) : (
                        <span>${item?.price_premium}</span>
                      )}
                    </label>
                    {/* <input type="radio" name="price" id={`price_premium_${idx}`} onClick={() => selectPriceHandle(item, "premium")} /> */}
                    {/* <span className="price_type">(Premium)</span> */}
                  </div>
                ) : (
                  ""
                )}
                <p>{item?.flight_type}</p>
              </div>
            )}

            {!isClickable && (
              <div className="end">
                {item?.price > 0 && (
                  <div className="flight_list_price">
                    <label className="black">
                      {item?.discounted ? (
                        <>
                          <span style={{ textDecoration: "line-through" }}>
                            ${item?.price}
                          </span>{" "}
                          <span style={{ color: "green" }}>
                            ${item?.discounted}
                          </span>
                        </>
                      ) : (
                        <span>${item?.price}</span>
                      )}
                    </label>
                    {/* <span className="price_type" >({item?.type})</span> */}
                  </div>
                )}
                <p>{item?.flight_type}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      {open && (
        <CustomModal
          key={parseInt(Math.random() * 9999)}
          onHide={() => setOpen(false)}
          item={itemData}
          selectPriceHandle={selectPriceHandle}
        />
      )}
    </>
  );
};

export default FlightList;
