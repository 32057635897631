// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flight_inputs_wrapper {
  display: flex;
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}
.flight_inputs_wrapper > div {
  flex: 1 1;
}

.react-select__control {
  border-radius: 0 !important;
  flex-direction: row-reverse;
  height: 100% !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open:hover {
  border: 1.5px solid var(--color_secondary) !important;
  box-shadow: 0 0 1px var(--color_secondary) !important;
}

@media screen and (max-width: 768px) {
  .flight_inputs_wrapper {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FlightInputs/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iDAAiD;EACjD,mBAAmB;AACrB;AACA;EACE,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,qDAAqD;EACrD,qDAAqD;AACvD;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".flight_inputs_wrapper {\n  display: flex;\n  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.05);\n  margin-bottom: 1rem;\n}\n.flight_inputs_wrapper > div {\n  flex: 1 1;\n}\n\n.react-select__control {\n  border-radius: 0 !important;\n  flex-direction: row-reverse;\n  height: 100% !important;\n}\n\n.react-select__indicator-separator {\n  display: none;\n}\n\n.react-select__control--is-focused,\n.react-select__control--menu-is-open:hover {\n  border: 1.5px solid var(--color_secondary) !important;\n  box-shadow: 0 0 1px var(--color_secondary) !important;\n}\n\n@media screen and (max-width: 768px) {\n  .flight_inputs_wrapper {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
