import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL

export default class Api {
  //get locations
  static getLocations = async (term) => {
    try {
      const res = await axios.get(`api/v1/locations?term=${term}`);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //get flights
  static getFlights = async ({ origin, destination, departure_date, arrival_date, flight_type }) => {
    let query = {
      origin,
      destination,
      departure_date,
      flight_type
    }

    if (arrival_date) query.arrival_date = arrival_date

    try {
      const res = await axios.get(`api/v1/flights?${new URLSearchParams(query).toString()}`);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };

  //post booking
  static postBooking = async (data) => {
    try {
      const res = await axios.post(`api/v1/booking`, data);
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  };
}
