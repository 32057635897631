import "./index.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img
          className="logo"
          src={logo}
          alt="FlightsDo"
        />
      </Link>
    </div>
  );
};

export default Header;
