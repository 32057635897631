// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgb(86 86 86 / 20%);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.custom_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-weight: 500;
}

.custom_modal_body {
  font-size: 14px !important;
}

.custom_modal_body .flight_airline img {
  width: 32px !important;
  height: 32px !important;
}

.custom_modal_body button {
  border-radius: 8px;
  width: 100%;
}

.custom_modal_body .btn_cancel {
  margin-top: 8px;
  background: #ffffff;
  color: var(--color_secondary);
  border: 1px solid var(--color_secondary);
}

.custom_modal_body .flight_list {
  margin: 0 !important;
  border: none !important;
}

.custom_modal_body .flight_item {
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,+BAA+B;EAC/B,0BAA0B;EAC1B,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gCAAgC;EAChC,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,6BAA6B;EAC7B,wCAAwC;AAC1C;;AAEA;EACE,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".custom_modal_overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 900;\n  background: rgb(86 86 86 / 20%);\n  backdrop-filter: blur(6px);\n  -webkit-backdrop-filter: blur(6px);\n}\n\n.custom_modal_head {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid #3e3e3e;\n  padding-bottom: 8px;\n  margin-bottom: 8px;\n  font-weight: 500;\n}\n\n.custom_modal_body {\n  font-size: 14px !important;\n}\n\n.custom_modal_body .flight_airline img {\n  width: 32px !important;\n  height: 32px !important;\n}\n\n.custom_modal_body button {\n  border-radius: 8px;\n  width: 100%;\n}\n\n.custom_modal_body .btn_cancel {\n  margin-top: 8px;\n  background: #ffffff;\n  color: var(--color_secondary);\n  border: 1px solid var(--color_secondary);\n}\n\n.custom_modal_body .flight_list {\n  margin: 0 !important;\n  border: none !important;\n}\n\n.custom_modal_body .flight_item {\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
