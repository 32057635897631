import { useLocation } from "react-router-dom";
import FlightList from "../../components/FlightList";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import { useLayoutEffect, useState } from "react";

const Confirmation = () => {
  const location = useLocation()
  const [data, setData] = useState()
  const [flightData, setFlightData] = useState()
  const [bookingId, setBookingId] = useState()

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const booking_id = queryParams.get('booking_id');
    setBookingId(booking_id)
  }, [location, location?.search])

  useLayoutEffect(() => {
    let data = sessionStorage.getItem("formData")
    if (data && data !== "undefined") {
      data = JSON.parse(data)
      setData(data)
    }
  }, [location])

  useLayoutEffect(() => {
    let data = sessionStorage.getItem("flightData")
    if (data && data !== "undefined") {
      data = JSON.parse(data)
      setFlightData(data)
    }
  }, [location])

  return (
    <div className="checkout_wrapper">
      <div>
        <ConfirmationAlert bookingId={bookingId} />
        <br />
        <div>
          <p className="checkout_heading">Hi! {data?.name}</p>
          <p style={{ fontWeight: 600 }}>Confirmation number: #{bookingId}</p>
          <p>
            Thank you for booking your travel with FlightsDo. We’ve sent a copy
            of your booking confirmation to your email address. It make take
            24-48 hours to complete the booking. You would receive your
            confirmation number directly from them and you can manage your
            flight booking directly with the airline. You can also find this
            page again in My trips.
          </p>
        </div>
        <br />
        <div>
          <p className="checkout_heading">Flight summary</p>
          <p style={{ fontWeight: 600 }}>Departing {new Date(flightData?.[0]?.date).toDateString()}</p>
          <FlightList
            data={[flightData?.[0]] || []}
            isClickable={false}
            isActive={false}
          />
          {
            flightData?.[1] && <>
              <p style={{ fontWeight: 600 }}>Returning {new Date(flightData?.[1]?.date).toDateString()} </p>
              <FlightList
                data={[flightData?.[1]] || []}
                isClickable={false}
                isActive={false}
              />
            </>
          }
        </div>
        <div>
          <p className="checkout_heading">Price breakdown</p>
          <div className="price_breakdown">
            <p>
              <span>Flights</span>
              <span>${data?.flightsPrice?.flight_fare}</span>
            </p>
            <p>
              <span>Booking Fee</span>
              <span>${data?.flightsPrice?.booking_fee}</span>
            </p>
            <p>
              <span>Total</span>
              <span>${data?.flightsPrice?.total_price}</span>
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Confirmation;
