// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout_wrapper {
  margin: 1rem 0;
  display: flex;
  gap: 3rem;
}

.checkout_wrapper > div:first-child {
  flex: 2 1;
}

.checkout_wrapper > div:last-child {
  flex: 1 1;
}

.checkout_heading {
  font-weight: 700;
  color: var(--color_secondary);
  font-size: 20px;
  margin-bottom: 1rem;
}

.checkout_wrapper .flight_list {
  margin-top: 0.75rem;
}

.checkout_wrapper .flight_item {
  padding: 0.5rem 0.75rem;
}

.checkout_pricing {
  font-weight: 700;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checkout_pricing .price {
  width: 80px;
  text-align: end;
  display: inline-block;
}

.cancellation_policy {
  margin-top: 1.5rem;
}

.cancellation_policy .heading {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 1rem;
}

.price_breakdown {
  font-weight: 600;
  color: #000;
}

.price_breakdown > p > span:first-child {
  width: 150px;
  display: inline-block;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .checkout_wrapper {
    gap: 1rem;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE;IACE,SAAS;IACT,sBAAsB;EACxB;AACF","sourcesContent":[".checkout_wrapper {\n  margin: 1rem 0;\n  display: flex;\n  gap: 3rem;\n}\n\n.checkout_wrapper > div:first-child {\n  flex: 2 1;\n}\n\n.checkout_wrapper > div:last-child {\n  flex: 1 1;\n}\n\n.checkout_heading {\n  font-weight: 700;\n  color: var(--color_secondary);\n  font-size: 20px;\n  margin-bottom: 1rem;\n}\n\n.checkout_wrapper .flight_list {\n  margin-top: 0.75rem;\n}\n\n.checkout_wrapper .flight_item {\n  padding: 0.5rem 0.75rem;\n}\n\n.checkout_pricing {\n  font-weight: 700;\n  color: #000;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.checkout_pricing .price {\n  width: 80px;\n  text-align: end;\n  display: inline-block;\n}\n\n.cancellation_policy {\n  margin-top: 1.5rem;\n}\n\n.cancellation_policy .heading {\n  font-weight: 600;\n  font-size: 18px;\n  margin-bottom: 1rem;\n}\n\n.price_breakdown {\n  font-weight: 600;\n  color: #000;\n}\n\n.price_breakdown > p > span:first-child {\n  width: 150px;\n  display: inline-block;\n  margin-bottom: 6px;\n}\n\n@media screen and (max-width: 768px) {\n  .checkout_wrapper {\n    gap: 1rem;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
