import "./index.css";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { ONE_WAY, ROUND_TRIP } from "../../utils/constants";

const CalenderView = ({ date, setDate, showMenuRef, setShowMenu, flightType, setFlightType }) => {
  const handleSelect = (e) => {
    if (flightType === ONE_WAY) {
      setDate({ startDate: e, endDate: "" })
      return
    }

    const [start, end] = e;
    setDate({ startDate: start, endDate: end });
  };

  useEffect(() => {
    let handler = (e) => {
      if (!showMenuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, []);

  return (
    <div
      className="calender_layout"
      ref={showMenuRef}
    >
      <div className="calender_layout_head">
        <div className="calender_trip">
          <div>
            <input
              type="radio"
              id={ROUND_TRIP}
              value={ROUND_TRIP}
              name="trip"
              checked={flightType === ROUND_TRIP}
              onChange={e => setFlightType(e.target.value)}
            />
            <label htmlFor={ROUND_TRIP}>Round Trip</label>
          </div>
          <div>
            <input
              type="radio"
              id={ONE_WAY}
              value={ONE_WAY}
              name="trip"
              checked={flightType === ONE_WAY}
              onChange={e => [setDate(prev => ({ ...prev, endDate: "" })), setFlightType(e.target.value)]}
            />
            <label htmlFor={ONE_WAY}>One Way</label>
          </div>
        </div>
        <div style={{ display: "flex", gap: 6 }}>
          <div className="calender_input">
            <span className="calender_icon">
              <i className="bi bi-calendar-event"></i>
            </span>
            <p>
              {date?.startDate
                ? `${date?.startDate?.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "2-digit",
                })?.replaceAll("-", "/")}`
                : "Depart"}
              {flightType === ROUND_TRIP
                ? date?.endDate
                  ? ` - ${date?.endDate?.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                  })?.replaceAll("-", "/")}`
                  : " - Return"
                : ""}
            </p>
          </div>
          <button
            onClick={() => setShowMenu(false)}
            className="btn btn_primary"
          >
            Done
          </button>
        </div>
      </div>
      <div style={{ padding: 12 }}>
        <DatePicker
          selected={date?.startDate}
          startDate={date?.startDate}
          endDate={date?.endDate}
          onChange={handleSelect}
          focusSelectedMonth={false}
          monthsShown={window.innerWidth > 768 ? 2 : 1}
          selectsRange={flightType === ONE_WAY ? false : true}
          minDate={new Date()}
          inline
        />
      </div>
    </div>
  );
};

export default CalenderView;
