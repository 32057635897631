// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotional_banner {
  background: var(--color_secondary);
  position: relative;
  text-align: center;
  color: #ffffff;
  padding: 1.25rem 10px;
}

.promotional_banner p {
  padding-right: 2.5rem;
}

.promotional_banner .close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/PromotionalBanner/index.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,eAAe;AACjB","sourcesContent":[".promotional_banner {\n  background: var(--color_secondary);\n  position: relative;\n  text-align: center;\n  color: #ffffff;\n  padding: 1.25rem 10px;\n}\n\n.promotional_banner p {\n  padding-right: 2.5rem;\n}\n\n.promotional_banner .close {\n  position: absolute;\n  top: 1.25rem;\n  right: 1.25rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
