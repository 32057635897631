// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deals_wrapper {
  padding: 1.5rem 0;
}

.deals_heading {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.deals_heading span {
  color: var(--color_secondary);
}

.deals_grid {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.deals_grid > div {
  flex: 1 1;
}

.deals_card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.05);
}

.deals_grid .deals_card > img {
  aspect-ratio: 5/4;
}

.deals_card > img {
  width: 100%;
}

.deals_card > div {
  background: #ffffff;
  padding: 0.5rem 1rem 1rem 1rem;
}

.deals_card .deals_card_location {
  font-weight: 600;
  margin-bottom: 3px;
}

.deals_card .deals_card_location span {
  color: var(--color_secondary);
}

.deals_card .deals_card_description {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .deals_grid {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .deals_grid > div {
    width: 100%;
  }
  .deals_card > img {
    aspect-ratio: 4/3;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Deals/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iDAAiD;AACnD;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".deals_wrapper {\n  padding: 1.5rem 0;\n}\n\n.deals_heading {\n  font-weight: 700;\n  font-size: 1.5rem;\n  text-align: center;\n  margin-bottom: 1.5rem;\n}\n\n.deals_heading span {\n  color: var(--color_secondary);\n}\n\n.deals_grid {\n  display: flex;\n  gap: 1.5rem;\n  margin-bottom: 1.5rem;\n}\n\n.deals_grid > div {\n  flex: 1 1;\n}\n\n.deals_card {\n  border-radius: 12px;\n  overflow: hidden;\n  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.05);\n}\n\n.deals_grid .deals_card > img {\n  aspect-ratio: 5/4;\n}\n\n.deals_card > img {\n  width: 100%;\n}\n\n.deals_card > div {\n  background: #ffffff;\n  padding: 0.5rem 1rem 1rem 1rem;\n}\n\n.deals_card .deals_card_location {\n  font-weight: 600;\n  margin-bottom: 3px;\n}\n\n.deals_card .deals_card_location span {\n  color: var(--color_secondary);\n}\n\n.deals_card .deals_card_description {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n@media screen and (max-width: 768px) {\n  .deals_grid {\n    flex-wrap: wrap;\n    flex-direction: column;\n  }\n\n  .deals_grid > div {\n    width: 100%;\n  }\n  .deals_card > img {\n    aspect-ratio: 4/3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
