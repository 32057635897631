import "./index.css";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CheckoutFlights from "../../components/CheckoutFlights";
import CheckoutForm from "../../components/CheckoutForm";
import Api from "../../api/api";

const Checkout = () => {
  const history = useHistory();
  const location = useLocation();
  const [flights, setFlights] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    date: '',
    gender: 'male'
  })

  const flightsPrice = useMemo(() => {
    return flights.reduce((total_price, flight) => {
      const discounted = parseFloat(flight?.discounted) || 0;
      const price = parseFloat(flight?.price) || 0;
      const fee = parseInt(flight?.fee)

      return {
        flight_fare: Math.ceil(total_price.flight_fare + (discounted > 0 ? discounted - fee : price)),
        total_price: Math.ceil(total_price.total_price + discounted || price),
        booking_fee: Math.ceil(total_price.booking_fee + fee)
      };
    }, { total_price: 0, booking_fee: 0, flight_fare: 0 });
  }, [flights])

  const handleChange = (e) => {
    const { value, name } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSaving(true)
    const data = { ...formData, ...flightsPrice, airline_id: flights?.map(item => item?.id)?.toString()?.replaceAll(",", ", ") }
    sessionStorage.setItem("formData", JSON.stringify({ name: data?.first_name, flightsPrice }))
    try {
      const res = await Api.postBooking({ ...data, date: new Date(data?.date).toDateString() })
      if (res?.status === 201) {
        history.push({
          pathname: '/confirmation',
          search: new URLSearchParams({ booking_id: res?.data?.data }).toString(),
        });
      }
    } catch (error) {

    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    let data = sessionStorage.getItem("flightData")
    if (data && data !== "undefined") {
      data = JSON.parse(data);
      setFlights(data || [])
    }
  }, [location.pathname])

  return (
    <div className="checkout_wrapper">
      <form
        onSubmit={handleSubmit}
      >
        <div>
          <p className="checkout_heading">Passenger information</p>
          <p>
            Enter the required information and be sure that it exactly matches
            the government-issued ID presented at the airport.
          </p>
          <CheckoutForm
            data={formData}
            setFormData={setFormData}
            setData={handleChange}
          />
        </div>
        <div>
          <p className="checkout_heading">Payment information</p>
          <p>
            We will redirect you to make your payment. Payments are not
            refundable.
          </p>
          <br />
          <button
            style={{ borderRadius: 8 }}
            className="btn btn_primary"
            disabled={isSaving}
          >
            {isSaving ? "Submitting..." : "Confirm"}
          </button>
        </div>
      </form>
      <div>
        {flights?.length > 0 && <>
          <CheckoutFlights data={flights} />
          <div className="checkout_pricing">
            <p>
              <span>Flights</span>
              <span className="price">${flightsPrice?.flight_fare}</span>
            </p>
            <p>
              <span>Booking Fee</span>
              <span className="price">${flightsPrice?.booking_fee}</span>
            </p>
            <p>
              <span>Total</span>
              <span className="price">${flightsPrice?.total_price}</span>
            </p>
          </div>
        </>}
        <div className="cancellation_policy">
          <p className="heading">Cancellation policy</p>
          <p>
            We typically book you on the lowest fare class (such as Basic
            Economy or Economy), while you may be able to change or cancel the
            flight with the airline or carrier, once we complete the booking, we
            cannot process a refund or cancel this flight on your behalf. If
            prices change or we cannot complete a booking we would add credits
            back to your account.
          </p>
        </div>
      </div>
    </div >
  );
};

export default Checkout;
