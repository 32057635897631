import american from "../../assets/flights/american.svg";

const CheckoutFlights = ({ data }) => {
  return (
    <div className="flight_list">
      {data?.map((item, index) => (
        <div
          className="flight_item"
          key={index + 1}
        >
          <div className="flight_airline">
            <img
              src={american}
              alt="American Airlines"
            />
            <div>
              <p className="black">American Airlines</p>
              {item?.flight_number?.split(";")?.map(fn => (<p style={{ fontSize: 14 }}>{fn}</p>))}
            </div>
          </div>
          <div className="end">
            <p className="black">{item?.flight_duration}</p>
            <p className="black" style={{ textTransform: "uppercase" }}>{item?.departure_time} - {item?.arrival_time}</p>
            <p className="black">{item?.date}</p>
            <p>{item?.flight_stop}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckoutFlights;
