import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { Route, useLocation } from "react-router-dom";
import { ROUTES } from "./utils/routes";
import PromotionalBanner from "./components/PromotionalBanner";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RightsReserved from "./components/RightsReserved";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {location.pathname === "/" && <PromotionalBanner />}

      <div className="page_wrapper">
        <Header />

        {ROUTES.map((route, index) => (
          <Route
            exact
            key={index}
            {...route}
          />
        ))}

        <Footer />
      </div>
      <RightsReserved />
    </>
  );
}

export default App;
