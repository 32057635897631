// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

.footer > div {
  width: 25%;
}

.footer_link_head {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 1rem;
}

.footer_links {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

@media screen and (max-width: 768px) {
  .footer {
    gap: 2rem;
    flex-wrap: wrap;
  }

  .footer > div {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE;IACE,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".footer {\n  padding-top: 2rem;\n  padding-bottom: 2rem;\n  display: flex;\n}\n\n.footer > div {\n  width: 25%;\n}\n\n.footer_link_head {\n  font-weight: 700;\n  font-size: 18px;\n  margin-bottom: 1rem;\n}\n\n.footer_links {\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n}\n\n@media screen and (max-width: 768px) {\n  .footer {\n    gap: 2rem;\n    flex-wrap: wrap;\n  }\n\n  .footer > div {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
