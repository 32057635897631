import "./index.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/flightsdo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <Link to="/">
          <img
            src={logo}
            alt="FlightsDo"
          />
        </Link>
      </div>
      <div>
        <p className="footer_link_head">About</p>
        <div className="footer_links">
          <Link to="#">About FlightsDo</Link>
          <Link to="#">How it works</Link>
        </div>
      </div>
      <div>
        <p className="footer_link_head">Partner with us</p>
        <div className="footer_links">
          <Link to="#">Partnership programs</Link>
          <Link to="#">Affiliate program</Link>
        </div>
      </div>
      <div>
        <p className="footer_link_head">Support</p>
        <div className="footer_links">
          <Link to="#">Contact us</Link>
          <Link to="#">Privacy Policy</Link>
          <Link to="#">Terms of service</Link>
          <Link to="#">Trust and safety</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
