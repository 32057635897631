import { components } from "react-select";

export const FromIcon = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.292852 9.81546C0.0279673 9.919 -0.0805463 10.2356 0.0651342 10.4799L1.81319 13.4108C2.06359 13.8306 2.58081 14.0079 3.0361 13.8299L19.9381 7.22298C20.7279 6.91426 21.1179 6.02374 20.8092 5.23396C20.4883 4.41301 19.5436 4.03019 18.7417 4.39614L13.7432 6.67727L6.77303 0.271251C6.48383 0.00545878 6.06849 -0.0723994 5.70266 0.0706026C5.08963 0.310231 4.85636 1.05604 5.22358 1.60227L9.69834 8.25839L2.85554 11.3571L0.724133 9.86692C0.598016 9.77875 0.436176 9.75943 0.292852 9.81546ZM21.6776 16.9521H1.14764V18.5313H21.6776V16.9521Z"
          fill="#6E7491"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const ToIcon = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.34152 4.47881C2.07104 4.39093 1.78638 4.56695 1.74415 4.84821L1.23752 8.223C1.16495 8.70643 1.45251 9.17146 1.91742 9.32252L19.1767 14.9304C19.9832 15.1924 20.8494 14.7511 21.1114 13.9446C21.3838 13.1063 20.896 12.2113 20.0439 11.9858L14.7322 10.5804L13.4041 1.20726C13.349 0.818355 13.0772 0.4948 12.7037 0.373422C12.0777 0.170029 11.4244 0.598838 11.362 1.25407L10.6019 9.2384L3.34571 7.29585L2.64239 4.79207C2.60078 4.64392 2.48787 4.52637 2.34152 4.47881ZM21.1776 17.9521H0.647583V19.5313H21.1776V17.9521Z"
          fill="#6E7491"
        />
      </svg>
    </components.DropdownIndicator>
  );
};
