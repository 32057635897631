import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FlightInputs from "../../components/FlightInputs";
import FlightList from "../../components/FlightList";
import { ONE_WAY, ROUND_TRIP } from "../../utils/constants";
import Api from "../../api/api";

const Flight = () => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [flightType, setFlightType] = useState("");
  const [date, setDate] = useState();
  const [flights, setFlights] = useState([]);
  const [departFlight, setDepartFlight] = useState();
  const [returnFlight, setReturnFlight] = useState();

  const departFlightHandler = (data) => {
    if (flightType === ONE_WAY) {
      setIsLoading(true);
      sessionStorage.setItem("flightData", JSON.stringify([{ ...data, date: date?.startDate }]))
      history.push("/checkout");
      return
    }
    setDepartFlight(data);
  }

  const returnFlightHandler = (data) => {
    setIsLoading(true);
    setReturnFlight(data);
  }

  const convertTimeToComparable = (timeString) => {
    const [time, period] = timeString?.replaceAll(" ", "")?.toLowerCase()?.match(/(\d+:\d+)([ap]m)/i)?.slice(1, 3);
    const [hours, minutes] = time.split(':').map(Number);
    const adjustedHours = period.toLowerCase() === 'pm' ? hours + 12 : hours;
    return adjustedHours * 60 + minutes;
  };

  const getFlightsData = useCallback(async ({ origin, destination, departure_date, arrival_date, flight_type }) => {
    setIsLoading(true);
    try {
      const res = await Api.getFlights({ origin, destination, departure_date, arrival_date, flight_type })
      if (res?.status === 200 && res?.data?.success) {
        let data = res?.data?.data?.filter(item => item?.flight_duration) || []
        const fee50 = 50
        const fee120 = 120
        data = data?.map(item => {
          let price = parseFloat(item?.price_economy) || 0
          let premium = parseFloat(item?.price_premium) || 0

          let newItem = { ...item }

          if (price > 500) {
            newItem = {
              ...newItem,
              economy_discount: Math.ceil(parseFloat(price * 0.55) + fee120),
              economy_fee: fee120
            }
          } else {
            newItem = {
              ...newItem,
              economy_discount: price > 0 ? Math.ceil(parseFloat(price * 0.55) + fee50) : 0,
              economy_fee: price > 0 ? fee50 : 0
            }
          }
          if (premium > 500) {
            newItem = {
              ...newItem,
              premium_discount: Math.ceil(parseFloat(premium * 0.55) + fee120),
              premium_fee: fee120
            }
          } else {
            newItem = {
              ...newItem,
              premium_discount: price > 0 ? Math.ceil(parseFloat(premium * 0.55) + fee50) : 0,
              premium_fee: price > 0 ? fee50 : 0
            }
          }

          return newItem;
        })

        data = data.sort((a, b) => {
          if (a.price_economy !== b.price_economy) {
            return a.price_economy - b.price_economy;
          }

          const startTimeA = convertTimeToComparable(a.departure_time);
          const startTimeB = convertTimeToComparable(b.departure_time);
          return startTimeA - startTimeB;
        });

        setFlights(data);
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }, [flights])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const origin = queryParams.get('origin');
    const destination = queryParams.get('destination');
    const departure_date = queryParams.get('departure_date');
    const arrival_date = queryParams.get('arrival_date');
    const flight_type = queryParams.get('flight_type');

    setFlightType(flight_type || ONE_WAY)
    setDate({ startDate: departure_date, endDate: arrival_date })

    if (origin && destination && departure_date) {
      // const url = `https://skiplagged.com/flights/${departure}/${returning}/${startDate}/${endDate ? endDate : ''}`
      getFlightsData({ origin, destination, departure_date, arrival_date, flight_type })
    }
  }, [location.search]);

  useEffect(() => {
    if (returnFlight) {
      sessionStorage.setItem("flightData", JSON.stringify([{ ...departFlight, date: date?.startDate }, { ...returnFlight, date: date?.endDate }]))
      history.push("/checkout");
    }
  }, [returnFlight]);

  useEffect(() => {
    if (isLoading) {
      setFlights([])
      setDepartFlight()
      setReturnFlight()
    }
  }, [isLoading]);

  return (
    <div>
      {isLoading && <div className="loader_overlay"></div>}
      <br />
      <FlightInputs />
      {flights?.length > 0 && <p style={{ marginBottom: 8, fontWeight: 600 }}>{flights?.length} results found</p>}
      {!departFlight && flights?.length > 0 ? (
        <>
          <p className="flight_heading">Choose a departing flight</p>
          <FlightList
            data={flights}
            setData={departFlightHandler}
            isClickable={true}
            isActive={false}
          />
        </>
      ) : departFlight && flightType !== ONE_WAY && flights?.length > 0 ? (
        <>
          <p style={{ fontWeight: 600 }}>
            Your{" "}
            <span style={{ color: "var(--color_secondary)" }}>departing</span>{" "}
            flight
          </p>
          <div className="selected_departing_flights">
            <FlightList
              data={[departFlight]}
              setData={departFlightHandler}
              isClickable={false}
              isActive={true}
            />
            <button
              className="btn btn_secondary"
              onClick={() => setDepartFlight(null)}
            >
              Change
            </button>
          </div>
        </>
      ) : <div style={{ textAlign: 'center', padding: 20 }}>{isLoading ? "Loading..." : "No such flights available. Try again later"}</div>}

      {flightType === ROUND_TRIP && departFlight && !returnFlight && flights?.length > 0 && (
        <>
          <p className="flight_heading">Choose a returning flight</p>
          <FlightList
            data={flights}
            setData={returnFlightHandler}
            isClickable={true}
            isActive={false}
          />
        </>
      )}
    </div>
  );
};

export default Flight;