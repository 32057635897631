import "./index.css";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { debounce } from 'lodash';
import { FromIcon, ToIcon } from "../../utils/icons";
import { ONE_WAY, ROUND_TRIP, customStyles } from "../../utils/constants";
import CalenderView from "../CalenderView";
import Api from "../../api/api";

const FlightInputs = () => {
  const location = useLocation();
  const history = useHistory();
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [flightType, setFlightType] = useState(ONE_WAY);
  const [departure, setDeparture] = useState(null);
  const [returning, setReturning] = useState(null);
  const [departOptions, setDepartOptions] = useState([]);
  const [returnOptions, setReturnOptions] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const showMenuRef = useRef();

  const getLocationsData = async term => {
    if (!term?.trim()) return [];
    try {
      const res = await Api.getLocations(term);
      if (res?.status === 200) {
        return res?.data?.hints?.map(item => ({
          value: item?._slug,
          label: item?.label,
        })) || [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const debouncedGetLocationsData = useCallback(
    debounce(async (term, setOptions) => {
      const options = await getLocationsData(term);
      setOptions(options);
    }, 300),
    []
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (!date?.startDate) return
    sessionStorage.setItem("inputData", JSON.stringify({
      departure, returning, departOptions, returnOptions, date, flightType
    }));

    let queryParams = { origin: departure?.value, destination: returning?.value };
    if (date?.startDate) queryParams['departure_date'] = date?.startDate?.toISOString().split('T')[0]
    if (date?.endDate && flightType === ROUND_TRIP) queryParams['arrival_date'] = date?.endDate?.toISOString().split('T')[0]
    queryParams['flight_type'] = flightType

    history.push({
      pathname: '/flights',
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  useLayoutEffect(() => {
    if (location.pathname === "/") return sessionStorage.removeItem("inputData");
    let data = sessionStorage.getItem("inputData");
    if (data && data !== "undefined") {
      data = JSON.parse(data);
      setDepartOptions(data?.departOptions || [])
      setReturnOptions(data?.returnOptions || [])
      setDeparture(data?.departure || null)
      setReturning(data?.returning || null)
      setFlightType(data?.flightType || ONE_WAY)
      setDate({ startDate: new Date(data?.date?.startDate), endDate: data?.date?.endDate ? new Date(data?.date?.endDate) : "" } || null)
    }
  }, [location?.pathname])

  return (
    <form
      onSubmit={submitHandler}
      className="flight_inputs_wrapper"
    >
      <Select
        value={departure}
        onChange={setDeparture}
        onInputChange={(term, { action }) => {
          if (action === 'input-change') {
            debouncedGetLocationsData(term, setDepartOptions)
          }
        }}
        options={departOptions}
        placeholder="From where?"
        components={{ DropdownIndicator: FromIcon }}
        styles={customStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        required
      />
      <Select
        value={returning}
        onChange={setReturning}
        onInputChange={(term, { action }) => {
          if (action === 'input-change') {
            debouncedGetLocationsData(term, setReturnOptions)
          }
        }}
        options={returnOptions}
        placeholder="Where to?"
        components={{ DropdownIndicator: ToIcon }}
        styles={customStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        required
      />
      <div className="calender_wrapper">
        <div
          className="calender_input"
          onClick={() => setShowMenu(true)}
        >
          <span className="calender_icon">
            <i className="bi bi-calendar-event"></i>
          </span>
          <p>
            {date?.startDate
              ? `${date?.startDate?.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "2-digit",
              })?.replaceAll("-", "/")}`
              : "Depart"}
            {flightType === ROUND_TRIP
              ? date?.endDate
                ? ` - ${date?.endDate?.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "2-digit",
                })?.replaceAll("-", "/")}`
                : " - Return"
              : ""}
          </p>
        </div>
        {showMenu && (
          <CalenderView
            date={date}
            setDate={setDate}
            flightType={flightType}
            setFlightType={setFlightType}
            showMenuRef={showMenuRef}
            setShowMenu={setShowMenu}
          />
        )}
      </div>
      <button className="btn btn_primary">Search</button>
    </form>
  );
};

export default FlightInputs;
