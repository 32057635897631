import "./index.css";
import { useState } from "react";

const ConfirmationAlert = ({ bookingId }) => {
  const [isShow, setIsShow] = useState(true);

  return (
    <>
      {isShow && (
        <div className="confirmation_alert">
          <p>
            Your flight will be booked within 24-48 hours! Your confirmation
            number is #{bookingId}
          </p>
          <span
            className="close"
            onClick={() => setIsShow(false)}
          >
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
      )}
    </>
  );
};

export default ConfirmationAlert;
