// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout_form {
  margin: 1rem 0;
}

.checkout_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.checkout_grid:not(:last-child) {
  margin-bottom: 1rem;
}

.checkout_grid :is(input, select) {
  flex: 1 1;
}

.checkout_grid :is(input, select) {
  outline: none;
  padding: 12px;
  border: 1px solid rgba(161, 176, 204, 1);
  border-radius: 4px;
}

.checkout_grid :is(input, select):is(:hover, :focus, :active) {
  border-color: var(--color_secondary);
  transition: 0.3s all ease-in-out;
}

.checkout_grid .react-datepicker {
  border: 1px solid #aeaeae !important;
}

.checkout_datepicker {
  min-width: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckoutForm/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,aAAa;EACb,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".checkout_form {\n  margin: 1rem 0;\n}\n\n.checkout_grid {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.checkout_grid:not(:last-child) {\n  margin-bottom: 1rem;\n}\n\n.checkout_grid :is(input, select) {\n  flex: 1;\n}\n\n.checkout_grid :is(input, select) {\n  outline: none;\n  padding: 12px;\n  border: 1px solid rgba(161, 176, 204, 1);\n  border-radius: 4px;\n}\n\n.checkout_grid :is(input, select):is(:hover, :focus, :active) {\n  border-color: var(--color_secondary);\n  transition: 0.3s all ease-in-out;\n}\n\n.checkout_grid .react-datepicker {\n  border: 1px solid #aeaeae !important;\n}\n\n.checkout_datepicker {\n  min-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
