// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/hero.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  display: grid;
  place-items: center;
  position: relative;
  min-height: 530px;
}

.hero::before {
  content: "";
  position: absolute;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: calc(100% + 70px);
  top: -70px;
  width: 100vw;
  z-index: -1;
}

.hero_text {
  font-size: 4rem;
  margin-bottom: 2rem;
  background-image: linear-gradient(180deg, #bd0c3a 50%, #605dec 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

@media screen and (max-width: 768px) {
  .hero_text {
    font-size: 3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Hero/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yDAAqD;EACrD,yBAAyB;EACzB,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,oEAAoE;EACpE,kBAAkB;EAClB,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".hero {\n  display: grid;\n  place-items: center;\n  position: relative;\n  min-height: 530px;\n}\n\n.hero::before {\n  content: \"\";\n  position: absolute;\n  background-image: url(\"../../assets/images/hero.svg\");\n  height: calc(100% + 70px);\n  top: -70px;\n  width: 100vw;\n  z-index: -1;\n}\n\n.hero_text {\n  font-size: 4rem;\n  margin-bottom: 2rem;\n  background-image: linear-gradient(180deg, #bd0c3a 50%, #605dec 100%);\n  color: transparent;\n  -webkit-background-clip: text;\n  background-clip: text;\n}\n\n@media screen and (max-width: 768px) {\n  .hero_text {\n    font-size: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
