export const ONE_WAY = "one_way"
export const ROUND_TRIP = "round_trip"

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "var(--color_secondary)"
      : state.isFocused
        ? "#605dec4f"
        : "transparent",
    color: state.isSelected
      ? "white"
      : state.isFocused
        ? "#000000"
        : "var(--color_primary)",
  }),
};
