import "./index.css";
import DatePicker from "react-datepicker";

const CheckoutForm = ({ data, setData, setFormData }) => {
  return (
    <div className="checkout_form">
      <div className="checkout_grid">
        <input
          type="email"
          name="email"
          value={data?.email}
          onChange={setData}
          placeholder="Email Address"
          required
        />
        <input
          type="number"
          name="phone_number"
          value={data?.phone_number}
          onChange={setData}
          placeholder="Phone number"
          required
        />
      </div>
      <div className="checkout_grid">
        <input
          type="text"
          name="first_name"
          value={data?.first_name}
          onChange={setData}
          placeholder="First Name"
          required
        />
        <input
          type="text"
          name="middle_name"
          value={data?.middle_name}
          onChange={setData}
          placeholder="Middle Name"
        />
        <input
          type="text"
          name="last_name"
          value={data?.last_name}
          onChange={setData}
          placeholder="Last Name"
          required
        />
      </div>
      <div className="checkout_grid">
        <input
          type="text"
          name="suffix"
          value={data?.suffix}
          onChange={setData}
          placeholder="Suffix"
        />
        <DatePicker
          name="date"
          selected={data?.date}
          onChange={e => setFormData(prev => ({ ...prev, date: e }))}
          placeholderText="Date of Birth"
          dateFormat="MM/dd/yyyy"
          maxDate={new Date()}
          className="checkout_datepicker"
          required
        />
        <select
          placeholder="Gender"
          name="gender"
          value={data?.gender}
          onChange={setData}
          required
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div className="checkout_grid">
        <input
          type="text"
          name="redress_number"
          value={data?.redress_number}
          onChange={setData}
          placeholder="Redress number"
        />
        <input
          type="text"
          name="known_traveler_number"
          value={data?.known_traveler_number}
          onChange={setData}
          placeholder="Known traveler number"
        />
      </div>
    </div>
  );
};

export default CheckoutForm;
