import deal1 from "../assets/images/deal1.png";
import deal2 from "../assets/images/deal2.png";
import deal3 from "../assets/images/deal3.png";
import deal4 from "../assets/images/deal4.png";

export const cards = [
  {
    id: 1,
    image: deal1,
    location: "The Bund",
    city: "Shanghai",
    description: "China’s most international city",
  },
  {
    id: 2,
    image: deal2,
    location: "Sydney Opera House",
    city: "Sydney",
    description: "Take a stroll along the famous harbor",
  },
  {
    id: 3,
    image: deal3,
    location: "Kōdaiji Temple",
    city: "Kyoto",
    description: "Step back in time in the Gion district",
  },
  {
    id: 4,
    image: deal4,
    location: "Tsavo East National Park",
    city: "Kenya",
    description:
      "Named after the Tsavo River, and opened in April 1984, Tsavo East National Park is one of the oldest parks in Kenya. It is located in the semi-arid Taru Desert.",
  },
];
