import { useState } from "react";
import "./index.css";

const PromotionalBanner = () => {
  const [isShow, setIsShow] = useState(true);
  return (
    <>
      {isShow && (
        <div className="promotional_banner">
          <p>Exclusive Do+ Membership: Enjoy 50% Off on Premium Cabins</p>
          <span
            className="close"
            onClick={() => setIsShow(false)}
          >
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
      )}
    </>
  );
};

export default PromotionalBanner;
