import "./modal.css";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import american from "../../assets/flights/american.svg";

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 350px;
  height: auto;
  padding: 14px;
  border-radius: 8px;
  background-color: #ffffff;

  & .bi::before {
    font-weight: 600 !important;
    cursor: pointer;
  }
`;

const CustomModal = ({ onHide, data, selectPriceHandle, item }) => {
  const modalRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!modalRef.current.contains(e.target)) {
        onHide();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [onHide]);
  return createPortal(
    <>
      <div className="custom_modal_overlay"></div>
      <Modal
        ref={modalRef}
        className="custom_modal_cont"
      >
        <div className="custom_modal_head">
          <p></p>
          <span onClick={onHide}>
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
        <div className="custom_modal_body">
          <div className="flight_list">
            <div className="flight_item">
              <div className="flight_airline">
                <img
                  src={american}
                  alt="American Airlines"
                />
                <div>
                  <p className="black">American Airlines</p>
                  <p style={{ fontSize: 12 }}>American Airlines</p>
                  <p style={{ fontSize: 12 }}>{item?.flight_duration}</p>
                  <p style={{ textTransform: "uppercase", fontSize: 12 }}>
                    {item?.departure_time} - {item?.arrival_time}
                  </p>
                </div>
              </div>
              <div className="end">
                {/* {item.price_economy > 0 && <div className="flight_list_price">
                  <label className="black">
                    {item?.economy_discount ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>${item?.price_economy}</span>{" "}
                        <span style={{ color: "green" }}>${item?.economy_discount}</span>
                      </>
                    ) : <span>${item?.price_economy}</span>}
                  </label>
                  <span className="price_type">(Economy)</span>
                </div>} */}

                {item.price_premium > 0 && (
                  <div className="flight_list_price">
                    <label className="black">
                      {item?.premium_discount ? (
                        <>
                          <span style={{ textDecoration: "line-through" }}>
                            ${item?.price_premium}
                          </span>{" "}
                          <span style={{ color: "green" }}>
                            ${item?.premium_discount}
                          </span>
                        </>
                      ) : (
                        <span>${item?.price_premium}</span>
                      )}
                    </label>
                    {/* <span className="price_type">(Premium)</span> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 8 }}>
            <button
              onClick={() => selectPriceHandle(item, "premium")}
              class="btn btn_primary"
            >
              Upgrade to Premium
            </button>
            <button
              onClick={() => selectPriceHandle(item, "economy")}
              class="btn btn_primary btn_cancel"
            >
              Stay in Economy
            </button>
          </div>
        </div>
      </Modal>
    </>,
    document.getElementById("portal"),
  );
};

export default CustomModal;
