import "./index.css";
import FlightInputs from "../FlightInputs";

const Hero = () => {
  return (
    <div className="hero">
      <div>
        <p className="hero_text">See the world, but for 50% off</p>
        <FlightInputs />
      </div>
    </div>
  );
};

export default Hero;
