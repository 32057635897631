import Home from "../pages/home";
import Flights from "../pages/flights";
import Checkout from "../pages/checkout";
import Confirmation from "../pages/confirmation";

export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/flights",
    component: Flights,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/confirmation",
    component: Confirmation,
  },
];
