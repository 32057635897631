// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation_alert {
  border-radius: 8px;
  color: rgba(0, 123, 101, 1);
  border: 1px solid rgba(0, 123, 101, 1);
  background: rgba(234, 255, 251, 1);
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

.confirmation_alert p {
  flex: 2 1;
}

.confirmation_alert .close {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfirmationAlert/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,sCAAsC;EACtC,kCAAkC;EAClC,aAAa;EACb,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".confirmation_alert {\n  border-radius: 8px;\n  color: rgba(0, 123, 101, 1);\n  border: 1px solid rgba(0, 123, 101, 1);\n  background: rgba(234, 255, 251, 1);\n  padding: 1rem;\n  display: flex;\n  gap: 1rem;\n}\n\n.confirmation_alert p {\n  flex: 2 1;\n}\n\n.confirmation_alert .close {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
