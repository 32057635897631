import "./index.css";

const RightsReserved = () => {
  return (
    <div className="rights_reserved">
      © {new Date().getFullYear()} REECARD LLC. All rights reserved. Flights are
      booked with our flight partners.
    </div>
  );
};

export default RightsReserved;
